import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
import { Admin, Member } from "../models";
const { persistAtom } = recoilPersist();
export const UserInfo = atom<Admin | undefined>({
    key: 'UserInfo',
    default: undefined, 
    effects_UNSTABLE: [persistAtom],
});
export const token = atom<string|undefined>({
    key: 'token',
    default: undefined
});