import moment from "moment";
import React, { useState } from "react";
import { Pagination } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGetLogList } from "../services/Query";
import TokenServices from "../services/TokenServices";
import { UserInfo } from "../stores";

export const Logs = () => {
  const [logPage, setlogPage] = useState<number>(1);
  const navigate = useNavigate();
  const renderTime = (vTime: string) => {
    const pos = vTime.indexOf(".");
    const rTime = vTime.substring(0, pos) + "-00:00";
    return moment.tz(rTime, "America/New_York").format("YYYY-MM-DD HH:mm:ss");
  };
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const {
    isFetching: logLoading,
    data: loglist,
    error: errorLoading,
    refetch: getLogList,
  } = useGetLogList(logPage);

  if (logLoading) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={"80px"}
          width={"80px"}
          color="#666666"
        />
      </div>
    );
  }
  if (errorLoading && errorLoading.message.indexOf("401") > -1) {
    // console.log(errorLoading?.message);
    TokenServices.updateLocalAccessToken("");
    setUserinfo(undefined);
    navigate("/login");
  }

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th></th> */}
            <th>username</th>
            <th>email</th>
            <th>action type</th>
            <th>action comment</th>
            <th>created time</th>
          </tr>
        </thead>
        <tbody>
          {loglist &&
            loglist.rows &&
            loglist.rows.map((it, idx) => (
              <tr>
                {/* <td>{idx + 1 + (loglist.page - 1) * 20}</td> */}
                <td>
                  {it.user.member_FirstName} {it.user.member_LastName}
                </td>
                <td>{it.user.member_Email}</td>
                <td>{it.type.log_Type}</td>
                <td>{it.actionComment}</td>
                <td>{renderTime(it.created)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <td colSpan={5} align="center">
            {loglist && (
              <Pagination>
                {/* <Pagination.First
                  disabled={loglist.page === 1}
                  onClick={() => setlogPage(1)}
                /> */}
                <Pagination.Prev
                  disabled={loglist.page === 1}
                  onClick={() => setlogPage((prev) => --prev)}
                />
                <Pagination.Item>
                  {loglist.page}/{loglist.totalpage}
                </Pagination.Item>
                <Pagination.Next
                  disabled={loglist.page === loglist.totalpage}
                  onClick={() => setlogPage((prev) => ++prev)}
                />
                {/* <Pagination.Last
                  disabled={loglist.page === loglist.totalpage}
                  onClick={() => setlogPage(loglist.totalpage)}
                /> */}
              </Pagination>
            )}
          </td>
        </tfoot>
      </table>
    </div>
  );
};
