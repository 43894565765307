import { useMutation, useQuery } from "react-query";
import {
  Activity,
  Admin,
  ExcelODto,
  ILogSummary,
  LogPage,
  LoginRes,
  LoginUser,
  Member,
  Prompt,
  SearchWeight,
  SummaryDto,
} from "../models";
import HttpService from "./HttpServices";

export const useSaveSearchWeight = () => {
  return useMutation<any, Error, string>(
    "save_search_weight",
    async (formdata) => {
      return await HttpService.saveSearchWeight(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useGSignin = () => {
  return useMutation<LoginUser, Error, string>(
    ["login"],
    async (formdata) => {
      return await HttpService.googleSignin(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useSaveUser = () => {
  return useMutation<any, Error, string>(
    ["save_user"],
    async (formdata) => {
      return await HttpService.RegUser(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useGetUsers = () => {
  return useQuery<Member[] | undefined, Error>(
    ["get_all_users"],
    async () => {
      return await HttpService.getUserList();
    },
    {
      retry: false,
    }
  );
};
export const useSummaryData = (startData: string, endDate: string) => {
  return useQuery<SummaryDto, Error>(
    ["get_summary_data", startData, endDate],
    async () => {
      return await HttpService.getSummaryData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLogs = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_logs", startData, endDate],
    async () => {
      return await HttpService.getLogSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLessonsLogs = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_lessons", startData, endDate],
    async () => {
      return await HttpService.getLogLessonsSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLessonsAll = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_lessons_all", startData, endDate],
    async () => {
      return await HttpService.getLogLessonsAllData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryActivitiesLogs = (
  startData: string,
  endDate: string
) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_activities", startData, endDate],
    async () => {
      return await HttpService.getLogActivitiesSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryActivitiesAll = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_activities_all", startData, endDate],
    async () => {
      return await HttpService.getLogActivitiesAllData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useGetActivities = () => {
  return useQuery<Activity[] | undefined, Error>(
    ["get_all_activities"],
    async () => {
      return await HttpService.getActivityData();
    },
    {
      retry: false,
    }
  );
};
export const useGetLogList = (page: number) => {
  return useQuery<LogPage | undefined, Error>(
    ["get_log_list", page],
    async () => {
      return await HttpService.getLogList(page);
    },
    {
      retry: false,
    }
  );
};
export const useGetPromptContent = () => {
  return useQuery<Prompt | null, Error>(
    ["get_prompt_content"],
    async () => {
      return await HttpService.getPromptContent();
    },
    {
      retry: false,
    }
  );
};
export const useSearchWeight = () => {
  return useQuery<SearchWeight | undefined, Error>(
    ["get_search_weight"],
    async () => {
      return await HttpService.getSearchWeight();
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
export const UseInsertUsersExcel = () => {
  return useMutation<string[], Error, string>(
    ["insert_user_excel"],
    async (frm: string) => {
      const res = await HttpService.postInsertDataExcel(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseDeleteUser = () => {
  return useMutation<any, Error, string>(
    ["delete_user_id"],
    async (id: string) => {
      const res = await HttpService.deleteUser(id);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const useQueryChat = () => {
  return useMutation<any, Error, string>(
    "query_chat_content",
    async (formdata) => {
      return await HttpService.queryActivityContent(formdata);
    },
    {
      retry: false,
    }
  );
};
export const usePostPromptContent = () => {
  return useMutation<any, Error, string>(
    "save_prompt_content",
    async (formdata) => {
      return await HttpService.savePromptData(formdata);
    },
    {
      retry: false,
    }
  );
};
