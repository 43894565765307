import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useQueryChat } from "../services/Query";
import { Key, useState } from "react";
import OpenAI from "openai";
const gptKey = "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";
const profiles = [
  `
  Teacher Profile

  teaching location
  Nonthaburi , Nonthaburi ,Thailand
  
  type of school
  Private school
  
  Passport country
  USA
  
  Course Info
  
  course name
  PDP Mathematics | Grade 2
  
  subject/domain 
  Mathematics
  
  companion print title(s)
  title
  Math Grade 2 Student Edition
  ISBN
  978-1583315798
  
  target grade level(s) 
  2nd
  
  course description
  The Purposeful Design 9-level math series was developed to provide a strong mathematical foundation that will accomplish the following:
  
  Engage students to understanding math concepts taught through manipulative use and modeling
  Provide necessary practice for students to carry out mathematical procedures accurately and efficiently
  Foster problem-solving skills through grade-level appropriate problem-solving guides and real-life application
  Lead students to see math as sensible, useful, and doable
  Prepare students to be mathematical thinkers, skilled to be successful at the next level
  
  Unit Info
  
  Chapter 6 | Time and Money
  
  key ideas
  Time: analog and digital clocks
  Time: time to five-minute intervals
  Time: elapsed time
  Time: calendars
  Time: seasons
  Money: value of coins: penny to half-dollar
  Money: finding equivalent amounts
  Money: adding and subtracting coins and dollar bills
  companion printed title(s)
  Math Grade 2 Student Edition
  pages or page range
  159-190
  unit description
  Lessons begin with telling time to the hour and quickly progress to telling time to 5-minute intervals. Because digital clocks are so pervasive, students will learn two different methods of telling time.
  
  Students will also learn about the calendar, holidays, and seasons. Telling time is challenging for many students. Continue to focus attention on time as the opportunity arises.
  
  This chapter also deals with money, another difficult topic. Since there are so many different ways to combine coins to make specific amounts of money, it is important to help students develop an ability to think about coins and their values. 
  
  
  Lesson Info
  
  Lesson 2: Time to the Half Hour
  ●Estimated Time: 65 minutes
  ●Lesson Objective(s): Students will tell and write time to the half hour.
  ●Lesson Vocabulary: Half Hour - 30 minutes
  Lesson Details:
  Activity Title: Worldview: A Time for Everything
  ●Description: Students read and interpret Ecclesiastes 3:1-8, discussing the significance of timing in life events and sharing personal experiences that occurred at the right time, followed by a collective moment of gratitude.
  ●Estimated Time: 5 minutes preparation, 10 minutes class time
  Activity Title: The Human Clock
  ●Description: Students create a human clock using large numbered cards and volunteers to learn about hour and minute hands, practicing telling time through interactive participation.
  ●Estimated Time: 5 minutes preparation, 10 minutes class time
  Activity Title: Learning About 30-Minute Intervals
  ●Description: Students explore the concept of 30 minutes as half an hour, using timelines and demonstration clocks to understand and practice telling time at half-hour intervals.
  Estimated Time: 5 minutes preparation, 10 minutes class time
  `,
  `
  Teacher Profile

teaching location
Chicago, Illinois

type of school
Private school

Passport country
USA



Course Info

course name
PDP Science | Grade 3

subject/domain 
Science

companion print title(s)
title
Science Grade 3 Student Edition


target grade level(s) 
3rd


Course description: 

Draw your students into the awe and
amazement of creation through God’s eyes with
the newly revised third-grade science textbook
from Purposeful Design Publications. This third
edition of Purposeful Design Elementary Science
will train up the next generation to recognize
God’s handiwork all around them and marvel at
His intelligent design.
Students will not just learn about science, they
will learn how to do science–all while engaging
in a biblical worldview perspective of God’s
creation around them. They will grow through
experiential, hands-on learning based on
scientific inquiry investigations and engineering
design process activities.


Unit Info

Unit description

Chapter 3: Fossils
Preparation: Chapter 3
3.1 The Study of Fossils
3.2 Finding Fossils
3.3 Body Fossils
3.4 Trace and True Form Fossils
3.5 Fossils Everywhere
3.6 Investigate: Digging Fossils
3.7 Chapter 3 Review

In Chapter 3: Fossils, students are invited on a journey back in time to explore the fascinating world of fossils and uncover the stories they hold about Earth's past. Through a lens of awe and wonder, students will delve into the study of fossils, not just as remnants of a bygone era, but as intricate pieces of God's meticulously designed creation. Each lesson is thoughtfully crafted to ignite students' curiosity and deepen their understanding of how fossils provide insights into the life, environments, and events of the ancient world.

Starting with an introduction to fossils in "Preparation: Chapter 3," students are equipped with the foundational knowledge necessary for their exploration. "The Study of Fossils" opens their minds to the scientific processes and techniques used by paleontologists, setting the stage for a hands-on investigative approach. As they progress to "Finding Fossils," learners are encouraged to consider the processes that lead to fossilization and the significance of these discoveries in understanding Earth's history.

The exploration deepens with "Body Fossils" and "Trace and True Form Fossils," where students examine the different types of fossils and what they reveal about ancient life forms and their behaviors. "Fossils Everywhere" broadens their perspective, showing the ubiquity of fossils and their relevance in today's world, fostering a sense of connection to the Earth's vast history.

In "Investigate: Digging Fossils," students actively engage in the scientific inquiry process, simulating the role of paleontologists through hands-on activities that emphasize the engineering design process. This experiential learning approach not only enhances their scientific skills but also instills a deeper appreciation for the intricate details of God's creation.

The unit culminates with the "Chapter 3 Review," where students reflect on their learning journey, consolidating their understanding and marveling at the intelligent design that underpins the natural world. Throughout this chapter, students are not merely learning about science; they are learning to see the world through a biblical worldview, recognizing the divine craftsmanship in every fossil and every story they tell. This approach aims to nurture their scientific curiosity while grounding them in their faith, inspiring the next generation to cherish and steward God's creation with wonder and responsibility.


Lesson Info

Lesson 3.6: Digging Fossils
Objectives
Content: Students will examine tools and choose which tool works best to excavate buried objects.
Worldview: Students will demonstrate their God-given ability to use tools.
Lesson Details:
Activity Title: Introduction to Fossil Excavation

Description: Introduce fossil excavation by discussing a dinosaur fossil and the tools paleontologists use.
Estimated Time: 10 minutes
Activity Title: Excavation Tools and Techniques

Description: Discuss the excavation process, tools used, and the importance of careful observation and data collection.
Estimated Time: 15 minutes
  `,
  `
  Teacher Profile

teaching location
Dallas, Texas

type of school
Public School

Passport country
Guatemala


Course Info

Course Name
Connecting with God

subject/domain 
Religion

companion print title(s)
title
Connecting with God Student Edition


target grade level(s) 
10th
11th
12th

Course Description

Welcome to
Connecting with God
All Scripture is God-breathed and is useful for
teaching, rebuking, correcting and training in
righteousness, so that the man of God may be
thoroughly equipped for every good work.
—2 Timothy 3:16–17
Connecting with God is a New Testament survey
course. It’s not an in-depth study of a Bible book
or books. Instead, it prepares students for in depth Bible study by helping them grasp the big
picture of the New Testament (NT).


Unit Info

Unit name:
Unit 2 | Introducing the Gospels

Unit description:

Introducing the Gospels
As we learned in Unit 1, the New Testament books
are arranged according to type of book rather
than in the order they were written. The first four
books—Matthew, Mark, Luke, and John—are
called Gospels.
Lead students to see that the Gospels provide a
needed transition from the Old Testament to the
New. They show how Jesus fulfilled Old Testament
promises about the Messiah. For Gentile readers, the
Gospels show that Jesus lived in a particular time
and place. He was a real person who really lived as a
human being. He’s not just a symbol or an idea.
PowerPoint 2.0 gives a brief overview of the
Gospels. Play the presentation—interrupting it
for comments if you wish—and then discuss it.
(Questions related to this presentation will be
included in Lesson 2.1 Checkup.)
•	 For Jews, show Jesus as the fulfillment of the Old
Testament promises.
•	 For Gentiles, give information and answer questions
about this Jewish man, Jesus.
•	 Variety of lengths
•	 Found in the Old Testament
•	 Found in other parts of the New Testament


Lesson Info

Lesson 2.1: Introducing the Gospel of Matthew

Objectives
Understand the significance of Old Testament quotations and allusions in the Gospel of Matthew.
Explore the socio-economic and cultural context of tax collectors in Jesus' time.
Lesson Details:
Activity Title: Exploring Old Testament Influences

Description: Students will identify Old Testament quotations and allusions in Matthew, create a chart of their findings, and summarize the significance.
Estimated Time: 20 minutes
Activity Title: Understanding Tax Collectors in Jesus’ Time

Description: Students research tax collectors during Jesus' era, focusing on their roles, earnings, and reasons for Jewish disdain, including references to tax collectors other than Matthew.
Estimated Time: 25 minutes
These activities are designed to deepen understanding of the Gospel of Matthew through interactive and research-oriented tasks.

  `,
];
const expectedComment = `
Example expected json type:
{
  name: "",
  description: "",
  key_concepts: ["", "", ""],
  preparation: [
    "”,
    “”,
    “”,
    “"
  ],
  instructions: [
    "",
    "",
    "",
    "",
    ""
  ],
  additional_notes: "",
  materials: ["", "", ""],
  prep_time: "",
  class_time: "",
  work_types: ["", ""],
  reusable: "",
  template_categories: ["", "", "", ""]
}
`;
export const CreateActivity = () => {
  const { mutate: queryChat, isLoading } = useQueryChat();
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [abstractObj, setAbstractObj] = useState<any | undefined>(undefined);
  const [description, setDescription] = useState("");
  const [isQuerying, setIsQuerying] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectProfile, setSelectProfile] = useState(-1);
  const [showProfile, setShowProfile] = useState(false);
  const sendCommand = async () => {
    const openai = new OpenAI({
      apiKey: gptKey, // This is the default and can be omitted
      dangerouslyAllowBrowser: true,
    });
    if (query || selectProfile != 0) {
      setIsQuerying(true);
      try {
        let prompt = query;
        if (selectProfile != 4) {
          prompt = profiles[selectProfile - 1];
        }
        prompt += "\n description: \n" + description + "\n";
        prompt += " " + expectedComment;
        const data = { query: prompt };
        queryChat(JSON.stringify(data), {
          onSuccess: (res) => {
            const resObj = res;
            console.log(resObj);
            setAbstractObj(resObj);
            // setResult(resObj);
            setModalShow(true);
          },
        });
        // const chatCompletion = await openai.chat.completions.create({
        //   messages: [{ role: 'user', content: prompt }],
        //   response_format: { type: 'json_object' },
        //   model: 'gpt-4-1106-preview',
        // });
        // const response = chatCompletion.choices[0].message.content || '';
        // const resObj = JSON.parse(response);
        // setAbstractObj(resObj);
        // // console.log(resObj);
        // setResult(response);
        // setModalShow(true);
      } catch (er) {
        setAbstractObj(undefined);
        setModalShow(false);
      }
      setIsQuerying(false);
    }
  };
  return (
    <div className="container searchform">
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>
          <b>describe your activity idea:</b>
          <div className="text-secondary">
            even just one sentence will do, but more detail will provide better
            results.
          </div>
        </Form.Label>
        <Form.Control
          as={"textarea"}
          rows={10}
          value={description}
          placeholder={`example: "I want to use cardboard and chopsticks to teach how to read analog clocks." or "I'd like to do a memory game next, it will work like this..."`}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Form.Group>
      <div className="row">
        <div className="col-4">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">select profile</InputGroup.Text>
            <Form.Select
              value={selectProfile}
              onChange={(e) => {
                if (e.target.value) {
                  setSelectProfile(Number(e.target.value));
                } else setSelectProfile(0);
              }}
            >
              <option></option>
              <option value={1}>PDP Math Grade 2</option>
              <option value={2}>PDP Science Grade 3</option>
              <option value={3}>PDP High School Bible</option>
              <option value={4}>Custom</option>
            </Form.Select>
          </InputGroup>
        </div>
        <div className="col">
          {selectProfile != 4 && selectProfile > 0 && (
            <button
              className="prim_btn px-3 py-1 rounded-2 fw-bold border-0 shadow-sm"
              onClick={() => {
                setShowProfile(true);
              }}
            >
              view profile
            </button>
          )}
        </div>
      </div>
      {selectProfile == 4 && (
        <div className="row">
          <div className="col-12">
            <Form.Group className="mb-2">
              {/* <Form.Label>Prompt</Form.Label> */}
              <Form.Control
                placeholder="write your profile context"
                value={query}
                as={"textarea"}
                rows={20}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </Form.Group>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end">
        <div>
          <button
            className="prim_btn px-3 py-1 rounded-2 fw-bold border-0 shadow-sm "
            disabled={
              (!query && selectProfile == 4) ||
              selectProfile == 0 ||
              (selectProfile > 1 && description == "")
            }
            onClick={() => {
              sendCommand();
            }}
          >
            {isQuerying || isLoading ? (
              <Spinner
                animation="border"
                size="sm"
                className="me-1"
                variant="info"
              />
            ) : null}
            generate activity
          </button>
        </div>
      </div>
      {/* <div
        dangerouslySetInnerHTML={{ __html: result.replaceAll('\n', '<br />') }}
      /> */}
      <Modal
        show={modalShow}
        size="lg"
        onHide={() => {
          setModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>activity details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {abstractObj?.name && (
            <div className="row mb-2">
              <div className="col-2">
                <b>name</b>
              </div>
              <div className="mt-2">{abstractObj?.name}</div>
            </div>
          )}
          {abstractObj?.description && (
            <div className="row mb-3">
              <div className="col-2">
                <b>description</b>
              </div>
              <div className="mt-2">{abstractObj?.description}</div>
            </div>
          )}
          {abstractObj?.key_concepts && (
            <div className="row mb-2">
              <div className="col-2">
                <b>key concepts</b>
              </div>
              <div className="mt-2">
                {(abstractObj?.key_concepts as string[]).join(", ")}
              </div>
            </div>
          )}
          {abstractObj?.preparation && (
            <div className="row mb-2">
              <div className="col-2">
                <b>preparation</b>
              </div>
              <div className="mt-2">
                <ul>
                  {(abstractObj?.preparation as string[])?.map(
                    (it, idx: Key | null | undefined) => (
                      <li key={idx}>{it}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
          {abstractObj?.instructions && (
            <div className="row mb-2">
              <div className="col-3">
                <b>instructions</b>
              </div>
              <div className="mt-2">
                <ol>
                  {(abstractObj?.instructions as string[])?.map(
                    (it, idx: Key | null | undefined) => (
                      <li key={idx}>{it}</li>
                    )
                  )}
                </ol>
              </div>
            </div>
          )}
          {abstractObj?.additional_notes && (
            <div className="row mb-2">
              <div className="col-3">
                <b>additional notes</b>
              </div>
              <div className="mt-2">{abstractObj?.additional_notes}</div>
            </div>
          )}

          <div className="row mb-2">
            {abstractObj?.class_time && (
              <>
                <div className="col-2">
                  <b>class time</b>
                </div>
                <div className="col-3">{abstractObj?.class_time}</div>
              </>
            )}
            {abstractObj?.prep_time && (
              <>
                <div className="col-2">
                  <b>prep time</b>
                </div>
                <div className="col-3">{abstractObj?.prep_time}</div>
              </>
            )}
          </div>

          {abstractObj?.materials && (
            <div className="row mb-2">
              <div className="col-3">
                <b>materials</b>
              </div>
              <div className="mt-2">
                {(abstractObj?.materials as string[])?.map((it, idx) => (
                  <span
                    className="material_item me-1 px-1 rounded-1 py-1 mb-1"
                    key={idx}
                  >
                    {it}
                  </span>
                ))}
              </div>
            </div>
          )}
          {abstractObj?.template_categories && (
            <div className="row mb-2">
              <div className="col-5">
                <b>template categories</b>
              </div>
              <div className="mt-2">
                {(abstractObj?.template_categories as string[])?.join(", ")}
              </div>
            </div>
          )}
          {abstractObj?.work_types && (
            <div className="row mb-2">
              <div className="col-3">
                <b>work types</b>
              </div>
              <div className="mt-2">
                {(abstractObj?.work_types as string[])?.join(", ")}
              </div>
            </div>
          )}
          {abstractObj?.reusable && (
            <div className="row mb-2">
              <div className="col-2">
                <b>reusable</b>
              </div>
              <div className="col">{abstractObj?.reusable}</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showProfile}
        size="lg"
        onHide={() => {
          setShowProfile(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>profile details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: profiles[selectProfile - 1]?.replaceAll("\n", "<br />"),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowProfile(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
