import React from "react";
import { Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import log from "../assets/images/edusfere_logo.png";
import { UserInfo } from "../stores";
interface Props {
  isLogoutable: boolean;
}
export const TopHeader = (props: Props) => {
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  return (
    <Navbar bg="light" expand="sm" fixed="top" className="box_shadow" >
      <div className="d-flex w-100 justify-content-between">
        <img src={log} height={35} alt="" className="ms-3" />
        {props.isLogoutable && (
          <div className="common_btns">
            <button
              className="border-0 bg-transparent me-3"
              onClick={() => {
                setUserinfo(undefined);
                navigate("/login");
              }}
            >
              logout
            </button>
          </div>
        )}
      </div>
    </Navbar>
  );
};
