import React from "react";
import { Footer } from "./Footer";
import { TopHeader } from "./TopHeader";

interface Props {
  children: React.ReactNode;
}
export const Layout = (props: Props) => {
  return (
    <>
      <TopHeader isLogoutable={true} />
      <div className="main_content mt-5">{props.children}</div>
    </>
  );
};
