import axios from "axios";
import {
  Activity,
  ILogSummary,
  LoginRes,
  LoginUser,
  LogPage,
  Member,
  Prompt,
  SearchWeight,
  SummaryDto,
} from "../models";
import TokenServices from "./TokenServices";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accepts: "application/json",
  },
  withCredentials: false,
});
apiClient.interceptors.request.use(async (config) => {
  const token = TokenServices.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && !config.headers["Authorization"]) {
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "application/json";
  return config;
});

const googleSignin = async (formData: string) => {
  const response = await apiClient.post<LoginUser>(
    "Login/adminsignin",
    formData
  );
  return response.data;
};
const getUserList = async () => {
  const response = await apiClient.get<Member[]>("Asset/UserList");
  return response.data;
};
const toggleUserType = async (memberId: string) => {
  const response = await apiClient.get(`Login/toggleMemberType/${memberId}`);
  return response.data;
};
const toggleUserValid = async (memberId: string) => {
  const response = await apiClient.get(`Login/toggleMemberValid/${memberId}`);
  return response.data;
};
const getLogList = async (page: number) => {
  const response = await apiClient.get<LogPage>(`Asset/LogList/${page}`);
  return response.data;
};
const getActivityData = async () => {
  var response = await apiClient.get<Activity[]>("Asset/ActivityList");
  return response.data;
};
const getSearchWeight = async () => {
  var response = await apiClient.get<SearchWeight>("Asset/SearchWeight");
  return response.data;
};
const saveSearchWeight = async (formData: string) => {
  const response = await apiClient.post<any>(
    "Asset/SaveSearchWeight",
    formData
  );
  return response.data;
};
const RegUser = async (formData: string) => {
  const response = await apiClient.post<any>("Asset/reg_user", formData);
  return response.data;
};
const deleteUser = async (id: string) => {
  const response = await apiClient.delete<any>(`Asset/delete_user/${id}`);
  return response.data;
};
const postInsertDataExcel = async (formdata: string) => {
  const response = await apiClient.post<string[]>(
    "Asset/insert_user_excel",
    formdata
  );
  return response.data;
};
const getSummaryData = async (startData: string, endDate: string) => {
  const response = await apiClient.get<SummaryDto>(
    `Asset/summary_data/${startData}/${endDate}`
  );
  return response.data;
};
const getLogSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_summary/${sdate}/${edate}`
  );
  return response.data;
};
const getLogLessonsSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_lessons/${sdate}/${edate}`
  );
  return response.data;
};
const getLogLessonsAllData = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_lessons_all/${sdate}/${edate}`
  );
  return response.data;
};
const getLogActivitiesAllData = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_activities_all/${sdate}/${edate}`
  );
  return response.data;
};
const getLogActivitiesSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_activities/${sdate}/${edate}`
  );
  return response.data;
};
const queryActivityContent = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Assist/generate_activity_message`,
    formData
  );
  return response.data;
};
const savePromptData = async (formData: string) => {
  const response = await apiClient.post<any>(
    `Asset/save_prompt_content`,
    formData
  );
  return response.data;
};
const getPromptContent = async () => {
  const response = await apiClient.get<Prompt | null>(
    `Asset/get_prompt_content`
  );
  return response.data;
};
const HttpService = {
  googleSignin,
  getUserList,
  toggleUserType,
  toggleUserValid,
  getLogList,
  getActivityData,
  getSearchWeight,
  saveSearchWeight,
  RegUser,
  postInsertDataExcel,
  getSummaryData,
  getLogSummary,
  getLogLessonsSummary,
  getLogActivitiesSummary,
  getLogLessonsAllData,
  getLogActivitiesAllData,
  deleteUser,
  queryActivityContent,
  getPromptContent,
  savePromptData,
};
export default HttpService;
