export interface Admin {
  id: string;
  admin_Name: string;
  admin_Email: string;
}
export interface Member {
  member_Id: string;
  member_FirstName: string;
  member_LastName: string;
  member_NickName: string;
  member_Email: string;
  member_Birthday: Date;
  member_Location: string;
  member_StudentAge: string;
  member_EducationLevel: string;
  member_ExperienceLevel: string;
  member_Country: string;
  member_City: string;
  member_Province: string;
  member_SchoolName: string;
  member_SchoolType: string;
  member_CheckedEmail: boolean;
  member_Profile_imgURL: string;
  member_Cover_imgURL: string;
  member_Location_private: boolean;
  member_Type: string;
  member_Payment_ExpiredDay: Date;
  member_Shared: boolean;
  member_Verified: boolean;
  member_Created: string;
  member_Updated: string;
  member_FirstUse: boolean;
  member_IsGUser: boolean;
  member_ConfirmEmail: string;
  _Level: number;
}
export interface LogType {
  id: string;
  log_Type: string;
}
export interface Log {
  id: string;
  user: Member;
  type: LogType;
  actionComment: string;
  created: string;
}
export interface LogPage {
  totalpage: number;
  totalcount: number;
  page: number;
  rows: Log[];
}
export interface LoginUser {
  token: string;
  member: Admin;
}
export interface Activity {
  activity_Id: string;
  activity_Name: string;
  activity_Type: string;
  activity_Desc: string;
  activity_Preparation: string;
  activity_Instructions: string;
  activity_AdditionalNote: string;
  activity_Materials: string;
  activity_PrepTime: string;
  activity_UseTime: string;
  activity_IdeaTypes: string;
  activity_AfterChangable: boolean;
  activity_LanguageFocus: string;
  activity_SkillFocus: string;
  activity_LessonStage: string;
  activity_ClassSizeHigh: string;
  activity_ClassSizeLow: string;
  activity_EnergyLevelLow: string;
  activity_EnergyLevelHigh: string;
  activity_ProficiencyLevelHigh: string;
  activity_ProficiencyLevelLow: string;
  activity_StudentAgeLow: string;
  activity_StudentAgeHigh: string;
  activity_DocUrl: string;
  activity_AdaptedDate: string;
  activity_Adaptation: boolean;
  activity_Created: string;
  activity_Updated: string;
  isDraft: boolean;
  isPrivate: boolean;
  isBookMarked: boolean;
  activity_EmptyDoc: boolean;
  activity_Status: boolean;
  orgActivity?: Activity;
  creator?: Member;
  _bookmarkedMembers?: Member[];
}
export interface SearchWeight {
  id: string;
  categoryWeight: number;
  class_Age_Weight: number;
  class_Proficiency_Weight: number;
  class_Size_Weight: number;
  class_Energy_Weight: number;
  class_Context_Weight: number;
  classWeight: number;
}
export type LoginRes = {
  member: Member;
  token: string;
};
export const levelName = ["owner", "Course admin", "Course member"];
export interface ExcelODto {
  firstName: string;
  lastName: string;
  email: string;
  school_name?: string;
  typeOfSchool: string;
  country: string;
  city: string;
  province: string;
}
export interface SummaryDto {
  loginCount: number;
  readLesson: number;
  readActivity: number;
  totalActivityCount: number;
  totalLessonCount: number;
}
export interface ISummary {
  title: string;
  count: number;
}
export interface ILogSummary {
  summaryLogs: ISummary[];
}
export interface Prompt {
  id: string;
  description: string;
  preLoadDescription: string;
}