import moment from "moment";
import React, { useRef, useState } from "react";
import { Pagination } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useGetActivities, useGetLogList } from "../services/Query";
import TokenServices from "../services/TokenServices";
import { UserInfo } from "../stores";
import { ConfirmModal } from "../components/ConfirmModal";
export const Activities = () => {
  // const [logPage, setlogPage] = useState<number>(1);
  const navigate = useNavigate();
  const renderTime = (vTime: string) => {
    const pos = vTime.indexOf(".");
    const rTime = vTime.substring(0, pos) + "-00:00";
    return moment.tz(rTime, "America/New_York").format("YYYY-MM-DD HH:mm:ss");
  };
  const [confirmShow, setconfirmShow] = useState(false);
  const msg = useRef<string>("");
  const deleteId = useRef<string>("");
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  const {
    isLoading,
    data: activities,
    refetch: getData,
    error: errorLoading,
  } = useGetActivities();

  if (isLoading) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={"80px"}
          width={"80px"}
          color="#666666"
        />
      </div>
    );
  }
  if (errorLoading && errorLoading.message.indexOf("401") > -1) {
    // console.log(errorLoading?.message);
    TokenServices.updateLocalAccessToken("");
    setUserinfo(undefined);
    navigate("/login");
  }

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>title</th>
            <th>description</th>
            <th>creator</th>
            <th>updated time</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {activities &&
            activities.map((it, idx) => (
              <tr className={it.isDraft ? "text-muted" : "text-body fw-bold"}>
                <td>{idx + 1}</td>
                <td>{it.activity_Name}</td>
                <td>{it.activity_Desc}</td>
                <td>{it.creator && `${it.creator.member_NickName}`}</td>
                <td>{renderTime(it.activity_Updated)}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      msg.current = `do you want to delete '${it.activity_Name}'?`;
                      deleteId.current = it.activity_Id;
                      setconfirmShow(true);
                    }}
                  >
                    delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <ConfirmModal
        show={confirmShow}
        content={msg.current}
        leftTitle={"no"}
        rightTitle={"yes"}
        onOk={() => setconfirmShow(false)}
        onDiscard={() => setconfirmShow(false)}
      />
    </div>
  );
};
