import { useState } from "react";
import * as xlsx from "xlsx";
import { ExcelODto } from "../../models";
import { Button, Container, InputGroup, Modal, Spinner } from "react-bootstrap";
import { UseInsertUsersExcel } from "../../services/Query";
interface Props {
  show: boolean;
  onOk: () => void;
  onConfirm: () => void;
}

export const AddUserModal = (props: Props) => {
  const [excelfile, setEditExcelFile] = useState<File | null>(null);
  const [errorUsers, setErrorUsers] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [listData, setListData] = useState<ExcelODto[]>([]);
  const { mutate: insertData, isLoading: isInserting } = UseInsertUsersExcel();
  const getJson = () => {
    if (excelfile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = xlsx.read(data, { type: "file", cellDates: true });
        // console.log(workbook);
        const sheetName = workbook.SheetNames[0];
        // console.log(workbook.SheetNames);
        const worksheet = workbook.Sheets[sheetName];
        const jsonArry = xlsx.utils.sheet_to_json(worksheet) as any[];
        if (jsonArry.length > 0) {
          const obj = jsonArry[0];
          const keys = Object.keys(obj);
          const newData: ExcelODto[] = [];
          jsonArry.forEach((it) => {
            const newObj: ExcelODto = {
              lastName: it[keys[1]],
              firstName: it[keys[0]],
              email: it[keys[2]],
              school_name: it[keys[3]],
              typeOfSchool: it[keys[4]],
              country: it[keys[5]],
              province: it[keys[6]],
              city: it[keys[7]],
            };
            newData.push(newObj);
          });
          setListData(newData);
        }
      };
      reader.readAsArrayBuffer(excelfile);
    }
  };

  const onInsert = () => {
    if (listData.length) {
      const data = JSON.stringify(listData);
      // fmData.append('afile', excelfile);
      insertData(data, {
        onSuccess: (res) => {
          if (res.length > 0) {
            setErrorUsers(true);
            setErrorMsg(
              `The following users already exist in the system now. <p>${res.join(
                ","
              )}</p>
                  The rest of users are registered successfully.`
            );
          }
          props.onConfirm();
        },
      });
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className={`confirm`}
      size="lg"
      centered={true}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Body>
        <h6 className="mb-3">Load *.excel or *.csv File</h6>
        <div className="row mb-3">
          <div className="col-4">
            <InputGroup>
              <input
                type="file"
                accept=".xlsx"
                onChange={(e) => {
                  if (e.target.files) {
                    setEditExcelFile(e.target?.files[0]);
                  }
                }}
              />
            </InputGroup>
          </div>
          <div className="col d-flex justify-content-end">
            <Button
              variant="success"
              className="fg-medium"
              disabled={excelfile == null}
              onClick={() => getJson()}
            >
              {isInserting && (
                <Spinner
                  animation="border"
                  size="sm"
                  className="me-1"
                  variant="light"
                />
              )}
              Preview before loading excel users
            </Button>
          </div>
        </div>
        <div className="d-flex">
          <table className="table table-bordered">
            <thead>
              <tr className="fg-medium">
                <th>name</th>
                <th>email</th>
                <th>school name</th>
                <th>school type</th>
                <th>country</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((item, index) => (
                <tr key={index} className="fg-normal">
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.school_name}</td>
                  <td>{item.typeOfSchool}</td>
                  <td>
                    {item.country} {item.province} {item.city}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Container className="confirm_btns d-flex justify-content-between mt-3">
          <button className="gray rounded-2 px-3" onClick={() => props.onOk()}>
            Close
          </button>
          <button
            className="main rounded-3 px-3"
            disabled={excelfile == null || listData.length == 0}
            onClick={() => onInsert()}
          >
            {isInserting && (
              <Spinner
                animation="border"
                size="sm"
                className="me-1"
                variant="light"
              />
            )}
            Save
          </button>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
