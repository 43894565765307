import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { GoBackModal } from "../components/GoBackModal";
import { UserInfo } from "../stores";
import { useRecoilState } from "recoil";

export const Wrap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [showGoto, setshowGoto] = useState(false);
  return (
    <Layout>
      <div className="d-flex">
        <div className="left_side">
          <ul className="menu">
            <li
              className={`cursor ${
                location.pathname.indexOf("users") > 0 ? "selected" : ""
              }`}
              onClick={() => {
                navigate("users");
              }}
            >
              users
            </li>
            <li
              className={`cursor ${
                location.pathname.indexOf("overview") > 0 ? "selected" : ""
              }`}
              onClick={() => {
                navigate("overview");
              }}
            >
              overview
            </li>
            <li
              className={`cursor ${
                location.pathname.indexOf("create_activity") > 0
                  ? "selected"
                  : ""
              }`}
              onClick={() => navigate("create_activity")}
            >
              AI activity generator
            </li>
            <li
              className={`cursor ${
                location.pathname.indexOf("admin_prompt") > 0 ? "selected" : ""
              }`}
              onClick={() => navigate("admin_prompt")}
            >
              AI admin prompt
            </li>
            <li
              className={`cursor ${
                location.pathname.indexOf("preload_prompt") > 0
                  ? "selected"
                  : ""
              }`}
              onClick={() => navigate("preload_prompt")}
            >
              AI preload content prompt
            </li>
          </ul>
        </div>
        <div className="flex-fill pt-3 px-3">
          <Outlet />
        </div>
      </div>
      <GoBackModal
        show={showGoto}
        onOk={() => {
          setshowGoto(false);
          navigate("users");
        }}
      />
    </Layout>
  );
};
